import axios from 'axios'
import qs from 'qs';
import {
  showToast,
  showFailToast,
  showLoadingToast,
  closeToast
} from 'vant';
import router from '@/router';
import store from '@/store';
import {
  getNewToken
} from '@/utils/home';
import {
  getUserInfo
} from '@/utils/my';
import wx from 'weixin-js-sdk';
let pro = process.env;
let base = pro.NODE_ENV == "development" ? '/api' : '/api-evimed'
export const pendingRequests = new Map();
export {
  base
};
let timer = null;
const callAppExit = cnative.callFactory('applyAuthorize', '_getopenid', 'Yxcz')
const instance = axios.create({
  baseURL: base,
  timeout: 15 * 60 * 1000,
  headers: {
    post: {
      // 'Content-Type': 'application/x-www-form-urlencoded'
      'Content-Type': 'application/json',
    },
    client: 'app',
    versionCode: 8,
    from: ''
  },
  paramsSerializer: {
    serialize: function (params) {
      return qs.stringify(params, {
        arrayFormat: 'repeat'
      })
    }
  },
  withcredentials: true
});
// 预览页面
let preView=[
  '/aianalysisdetails',
  '/documents',
  '/guidedetails',
  '/instructdetails',
  '/signIn',
  '/authorDetail',
  '/category'
]
// 进度条页面
let progressPage = [
  '/testDetail',
  '/decision-report-surper',
  '/decision-report-xz',
  '/safedetail',
  '/toadd',
  '/aianalysisdetails',
  '/safetransfer',
  '/safetransfer/safedetail',
  '/safetransfer/toadd',
  '/reportTransfer',
  '/reportTransfer/decision-report-surper',
  '/documents'
]
let isnotLoading = [
  // '/FineScreenController/search',
  // '/archive-api/guide/get',
  // '/archive-api/guide/more/list',
  // '/archive-api/guide/relate/list',
  '/FineScreenController/appGeneralSearchHistory',
  '/FineScreenController/deleteHistoryByScreenId',
  '/FineScreenController/emptyAppGeneralSearchHistory',
  '/archive-api/guide/filter/get',
  '/system/task/check/get',
  '/medicine-api/questions/outline',
  '/FineScreenController/screen',
  '/FineScreenController/multi/search',
  // '/drug-api/countrys/get',
  // 'subscribe-api/getbyclass',
  '/system/user/myself',
  '/analyze-api/topic/history',
  '/FineScreenController/TopicAnalysisController/history',
  '/system/popup/get',
  '/system/balance/account/my',
  '/system/wechat/yydy',
  '/yiling/operation',
  '/system/wechat/operation',
  '/yiling/task/details',
  '/yiling/search/history',
  '/evaluation-api/url-to-base64',
  // 临床综合评价打字机效果
  '/evaluation-api/lxgpt/process',
  '/evaluation-api/guide-on-analysis-app',
  '/evaluation-api/traditional/guide-on-analysis-v2-app',
  '/evaluation-api/drug-indication',
  '/evaluation-api/drug-price',

  '/alert/getAssociationalWord',
  '/evaluation-api/get-associational-word',
  '/FineScreenController/TopicAnalysisController/history/get',
  '/FineScreenController/TopicAnalysisController/history/delete',

  '/medicine-api/questions/sensitive',
  '/FineScreenController/TopicAnalysisController/keyword',
  '/FineScreenController/collectionLiterature',
  '/evaluation-api/history',
  '/archive-api/guideStatus/put',
  '/archive-api/instructionStatus/put',
  '/alert/showHistory',
  '/archive-api/guide/history/get',
  '/archive-api/guide/latest/',
  '/FineScreenController/paper/recomend/get',
  '/drug-api/drug/history/get',
  '/drug-api/class/get',
  '/archive-api/guide/department/',
  '/FineScreenController/TopicAnalysisController/hot/get',
  'system/verifycode/send',
  '/system/user/updateneedcode',
  '/system/user/svip/check',
  '/yiling/answer/getQuestionAndAnswerInfoByUser',
  '/yiling/answer/getQuestionAndAnswerInfo',
  '/yiling/question/getSysQuestionAnswer',
  '/FineScreenController/TopicAnalysisController/reporting/notice',
  '/FineScreenController/TopicAnalysisController/recommend',

  '/evidence-api-based/retrieval-api/type-list',
  '/evidence-api/retrieval-api/synonym',
  '/evidence-api/retrieval-api/save-condition',
  '/medicine-api/wechat/create-secret-key',
  '/evidence-api-based/report-api/create',
  '/evidence-api-based/retrieval-api/type-list',
  '/evidence-api-based/retrieval-api/synonym',
  '/evidence-api-based/retrieval-api/save-condition',
  '/yiling/topic/getTopicAndQuestionList',
  '/medicine-api/ai-overview-api/outline',
  '/medicine-api/judging',
  '/medicine-api/translate',
  '/evidence-api/super-manual-api/createPc',
  // 购买相关
  '/system/balance/purchase/situation',
  '/system/balance/lingdou/price',
  '/FineScreenController/getPaperInterpret',
  '/yiling/topic/topicsAndQuestionList',
  '/yiling/sys/getSysMessages',
  '/medicine-api/ai-overview-api/paper-info',
  '/pay/order/get',
  // 日报相关
  '/manage-api/all-daily-news',

  '/system/task/lingdounum/get',
  '/system/user/mark/put',
  '/medicine-api/generate/step',
  '/medicine-api/questions/guess',
  '/system/balance/lingdou/get'

]
instance.interceptors.request.use((config) => {
  const controller = new AbortController();
  config.signal = controller.signal;
  pendingRequests.set(config.url, controller);

  if (config.url === '/FineScreenController/screen') {
    window.localStorage.removeItem('scrollTop');
  }
  if (!isnotLoading.includes(config.url) && router.currentRoute.value.path !== '/category') {
    store.state.loadingNum++
    if (!store.state.noLoading || !progressPage.includes(window.location.pathname)) {
      startLoading()
    }
  }else{
    /* Event-Stream原因需要关闭loading 
      单独做了一个开关控制loading
      需要在数据中传递 isloading来控制
    */
    if (config.data && config.data.isloading) {
      startLoading();
    }
  }
  // 渠道码
  if (window.location.href.includes('channelCode')) {
    config.headers["from"] = window.location.href;
  }

  if (config.url != 'auth/login' || config.url != '/auth/loginfree') {
    let token = window.localStorage.getItem("token")
    if (token) { //如果有token继续操作 如果没有token  跳转登录页
      config.headers["token"] = token;
    } else {
      config.headers["token"] = '';
    }
    return config;
  }
}, (err) => {
  console.log(err);

})
instance.interceptors.response.use((res) => {
  pendingRequests.delete(res.config.url);

  if (!isnotLoading.includes(res.config.url)) {
    store.state.loadingNum--;
    if (store.state.loadingNum == 0) {
      if (timer != null) {
        clearTimeout(timer);
      }
      timer = setTimeout(() => {
        if (store.state.loadingNum == 0) {
          if (res.config.url == '/FineScreenController/multi/academic_tracking/get') {
            setTimeout(() => {
              closeToast()
            }, 500)
            // closeToast()
          } else {
            closeToast()
          }
        }
      }, 200)
    }
  }
  if (res.data.code) {
    switch (res.data.code) {
      case (200):
        return Promise.resolve(res.data);
        break;
      default:
        showToast({
          message: res.data.msg,
          position: 'bottom',
        });
        return Promise.resolve(res.data);
    }
  }
}, (err) => {
  if (!isnotLoading.includes(err.config && err.config.url)) {
    store.state.loadingNum--
    if (store.state.loadingNum == 0) {
      closeToast()
    }
  }
  store.state.noLoading = false;
  if (err && err.response) {
    const status = err.response.status;
    switch (status) {
      case (401):
        callAppExit({
          clientId: "5f3df22ff0bd3614f27a115d7c849951"
        }, (openid) => {
          if (openid) {
            ysfwToken(openid);
          } else if (window.__wxjs_environment === 'miniprogram') {
            if (router.currentRoute.value.path == '/guideHome' || router.currentRoute.value.path == '/') {


            } else if (router.currentRoute.value.path == '/guideCate' || router.currentRoute.value.path == '/guideHistory') {
              wx.miniProgram.navigateTo({
                url: '/pages/login/index',
              })
              setTimeout(() => {
                router.replace('/guideHome');
              }, 500)
            } else {
              wx.miniProgram.navigateTo({
                url: '/pages/login/index',
              })
            }
          }
          else if(localStorage.isWx && preView.includes(router.currentRoute.value.path)){
            let str='';
            if(router.currentRoute.value.path =='/documents'){
              let obj=JSON.parse(router.currentRoute.value.query.data);
              str=`shareForLiteratureDetail?dataId=${obj.id}*screenId=${obj.screenId}`;
            }else if(router.currentRoute.value.path =='/guidedetails'){
              let obj=JSON.parse(router.currentRoute.value.query.data);
              str=`shareForGuideDetail?screenId=${obj.screenId}*source=${obj.title}`;
            }else if(router.currentRoute.value.path =='/category'){
              let obj=router.currentRoute.value.query;
              str=`shareForAISearch?word=${obj.data}*screenId=${obj.shareKey}*associate=${obj.associate}*trans=${obj.trans}`;
            }
            else{
              str=router.currentRoute.value.fullPath.slice(1).replaceAll('&','*');
            }
            if(isIOS()){
              router.push({path:'/open-app',query:{str:str}});
            }else{
              window.open('https://a.app.qq.com/o/simple.jsp?pkgname=com.sentum.evimed&android_schema=evimed://'+str,'_blank');
            }
          }
          else {
            store.state.lastVisitedPath = router.currentRoute.value.fullPath;
            router.replace('/vertifycode');
          }
        })
        break;

        // router.push('/vertifycode');
      default:
        store.state.reloadFlag = true;
        showFailToast('服务繁忙');
    }

  } else {
    let arr = [
      '/evidence-api/super-manual-api/createPc',
      '/evidence-api-based/report-api/create'
    ]
    if (err.config && err.config.url.inclcudes(arr)) {
      console.log('报告生成中断');
    } else {
      // store.state.reloadFlag = true;
      // showFailToast('服务繁忙');
      console.log(store.state.reloadFlag);
    }
  }
  console.log(err);
  return Promise.reject('')
})
const request = (option) => {
  return new Promise((resolve, reject) => {
    instance.request({
        ...option,
      }).then((res) => {
        resolve(res)
      })
      .catch((res) => {
        reject(res)
      })
  })
}
const startLoading = () => {
  showLoadingToast({
    message: '加载中...',
    forbidClick: true,
    // backgroundColor: 'black',
    icon: require('@/assets/aiselect/isloading.gif'),
    duration: 0,
    // className:"isallloading",
    // overlay:true
  });
}
// 医师服务生成token
const ysfwToken = (openid) => {
  const clientId = "5f3df22ff0bd3614f27a115d7c849951";
  const returnUrl = encodeURIComponent("https://wap.evimed.com/home");
  // const returnUrl = encodeURIComponent("https://70816s008v.goho.co/home");
  // const returnUrl = encodeURIComponent("http://20.20.20.11:8080/home");
  // const returnUrl = encodeURIComponent("https://m.evimed.com/home");
  const loginUrl = "https://login.yishifuwu.cn/app/index.html?clientId=";
  // alert(`${loginUrl}${clientId}&returnUrl=${returnUrl}&type=base`)
  if (router.currentRoute.value.query.oauthCode && router.currentRoute.value.query.oauthCode != 0) {
    let codes = router.currentRoute.value.query.oauthCode
    localStorage.setItem('oauthCode', codes)
    getNewToken({
        authCode: codes
      }).then((res) => {
        if (res.code == 200) {
          localStorage.setItem('token', res.token)
          localStorage.setItem('openid', openid)
          getUserInfo().then(re => {
            store.commit("updateUserInfo", re.data);
            location.reload();
          })
        }
      })
      .catch((e) => {})
  } else if (router.currentRoute.value.query.oauthCode == 0) {
    window.location.replace(`${loginUrl}${clientId}&returnUrl=${returnUrl}&type=base`);
  } else {
    window.location.replace(`${loginUrl}${clientId}&returnUrl=${returnUrl}&type=base`);
  }
}
const isIOS=()=>{
  const ua = navigator.userAgent;
  const platform = navigator.platform.toLowerCase();
  return (/iPhone|iPad|iPod/i.test(ua) ||
      platform.includes('iphone') ||
      platform.includes('ipad') ||
      platform.includes('ipod')) && !window.MSStream;
}
if(localStorage.isWx){
  router.beforeEach((to, from, next) => {
    if(to.path=='/open-app'){
      next();
    }
    else if(preView.includes(from.path)){
      let str='';
      if(from.path =='/documents'){
        let obj=JSON.parse(from.query.data);
        str=`shareForLiteratureDetail?dataId=${obj.id}*screenId=${obj.screenId}`;
      }else if(from.path =='/guidedetails'){
        let obj=JSON.parse(from.query.data);
        str=`shareForGuideDetail?screenId=${obj.screenId}*source=${obj.title}`;
      }else if(from.path =='/category'){
        let obj=from.query;
        str=`shareForAISearch?word=${obj.data}*screenId=${obj.shareKey}*associate=${obj.associate}*trans=${obj.trans}`;
      }
      else{
        str=from.fullPath.slice(1).replaceAll('&','*');
      }
      if(isIOS()){
        next({path:'/open-app',query:{str:str}});
      }else{
        window.open('https://a.app.qq.com/o/simple.jsp?pkgname=com.sentum.evimed&android_schema=evimed://'+str,'_blank');
      }
    }
    else{
      next();
    }
  })
}
// 全局取消所有请求的方法
export const cancelAllRequests = () => {
  pendingRequests.forEach(controller => controller.abort());
  pendingRequests.clear();
};
export default request